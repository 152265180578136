import React, { useContext, useState, useEffect } from 'react';
import { useLoaderData, redirect } from 'react-router-dom';
import { SearchResults as SearchResultsComp } from '../../components/SearchResults';
import mapping from '../../utils/mapping';
import Queries from '../../utils/queries';
import {  toast } from 'react-toastify';
import GlobalContext from '../../store/context';
import { filter } from '../../utils/document/Filter';
import { paginate } from '../../utils/document/Filter';
import DropdownFilter from '../../components/Dropdowns/DropdownFilter';
import { useNavigation } from 'react-router-dom';

function SearchResults() {
  const { documents, expression } = useLoaderData();
  const [selectedFormat, setSelectedFormat] = useState('Tous');
  const [displayCount, setDisplayCount] = useState(process.env.REACT_APP_DISPLAY_COUNT_DOCS);
  const [currentPage, setCurrentPage] = useState(1); 
  const navigation = useNavigation();
  const { setIsSearchLoading } = useContext(GlobalContext);

  const searching =
  navigation.location &&
  new URLSearchParams(navigation.location.search).has(
    "expression"
  );

  useEffect(() => {
    // console.log( "searching : ", searching )
    if (!searching) {
      setSelectedFormat('Tous');
      setDisplayCount(process.env.REACT_APP_DISPLAY_COUNT_DOCS);
      setCurrentPage(1);
  }
  
  setIsSearchLoading(searching);
  }, [searching]);

  const { paginatedDocuments, totalFiltered, totalPages } = paginate(
    documents,
    currentPage,
    displayCount
  );

  const applyFilters = (documents) => {
    return filter(documents, selectedFormat);
  };

  const filteredDocuments = applyFilters(paginatedDocuments);

  const handleFormatChange = (format) => {
    setSelectedFormat(format);
  };

  // const onChangeDisplayCount = (event) => {
  //   const newDisplayCount = event.target.value;
  //   setDisplayCount(newDisplayCount);
  //   const newTotalPages = Math.ceil(totalFiltered / newDisplayCount);
  //   if (currentPage > newTotalPages) {
  //     setCurrentPage(newTotalPages);
  //   }
  // };
  
  // const onChangeCurrentPage = (event) => {
  //   setCurrentPage(event.target.value);
  // };

  const onChangeDisplayCount = (event) => {
    const newDisplayCount = parseInt(event.target.value);
    if (newDisplayCount <= 0) {
      // we can display an error message here or prevent modification
      return;
    }
    setDisplayCount(newDisplayCount);
    const newTotalPages = Math.ceil(totalFiltered / newDisplayCount);
    if (currentPage > newTotalPages) {
      setCurrentPage(newTotalPages);
    }
  };
  
  const onChangeCurrentPage = (event) => {
    const newPage = parseInt(event.target.value);
    if (newPage <= 0 || newPage > totalPages) {
      //we can display an error message here or prevent modification
      return;
    }
    setCurrentPage(newPage);
  };

  return (
    <>
    {documents.length > 0 ? (
    <div className='mt-20 mb-16'>
      <div className="flex flex-col lg:flex-row items-center justify-between bg-gradient-to-b from-white via-gray-50 to-gray-50 dark:bg-gray-700 p-4 mx-auto w-full mb-5" >
        <div className="lg:ml-2 mb-2 lg:mb-0">
          <p className="flex flex-col lg:flex-row items-center">
            <span className="p-2 text-sm">Affichage</span>
            <input
              type="number"
              className="w-16 h-5 border border-gray-300 rounded"
              min={1}
              max={process.env.REACT_APP_MAX_DISPLAY_DOCS}
              value={displayCount}
              onChange={onChangeDisplayCount}
            />
            <span className="p-2 text-sm">Élément(s)</span>
          </p>
        </div>
        <div className="lg:ml-4 mb-2 lg:mb-0">
          <p className="flex flex-col lg:flex-row items-center">
            <span className="p-2 text-sm">Page</span>
            <input
              type="number"
              className="w-16 h-5 border border-gray-300 rounded"
              min={1}
              max={totalPages}
              value={currentPage}
              onChange={onChangeCurrentPage}
            />
            <span className="p-2">/</span>
            <span className="p-2 text-sm">{totalPages} page(s)</span>
          </p>
        </div>
        <DropdownFilter
          options={['Tous', 'Vidéo', 'Audio', 'Image', 'Texte']}
          onChange={handleFormatChange}
          selected={selectedFormat}
        />
        <p className="p-2 text-sm">{filteredDocuments.length} document(s) affiché(s)</p>
      </div>
      {
        
        filteredDocuments.length > 0
          ? <SearchResultsComp documents={filteredDocuments} />
          : (
              <div className="flex flex-col items-center justify-center h-screen">
                <div className="text-center">
                  <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <div>
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Aucun document trouvé</h5>
                    </div>
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Aucun document ne correspond à votre filtrage</p>
                  </div>
                </div>
              </div>
            )
      }
     </div>
     ) : (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="text-center">
          <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div>
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Aucun document trouvé</h5>
            </div>
            <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Aucun document disponible ne correspond à votre recherche</p>
          </div>
        </div>
      </div>
     )}
    </>
);

}

export default SearchResults;

export async function loader({ request  }) {
  const url = new URL(request.url);
  const expression = url.searchParams.get("expression");
  try {
    // setIsSearchLoading(true)
    const response = await Queries.documentsSearch( expression, process.env.REACT_APP_FOLDER_ID );
    const documents = mapping.document(response.data);
    // setIsSearchLoading(false)
    // if( documents.length ){
    //   return { documents };
    // }else{
    //   toast.info("Aucun document disponible ne correspond à votre recherche");
    //   return redirect("/publications");
    // }
    return { documents, expression };
  } catch (error) {
    console.error(error);
  }
};