import React, { useEffect, useState, useContext } from 'react';
import { initFlowbite } from 'flowbite';
import { useLocation, useSubmit, Link } from 'react-router-dom'; 
import { Link as Scroll } from 'react-scroll';
import GlobalContext from '../../store/context';
import { useTranslation } from 'react-i18next';
import {  toast } from 'react-toastify';

    const Header = () => {
      const location = useLocation();
      const { t } = useTranslation();
      const [isMobile, setIsMobile] = useState(false);
      const [ search, setSearch ] = useState('');
      const submit = useSubmit();
      const { 
          isMobileSidebarOpen, 
          setIsMobileSidebarOpen, 
          isSearchLoading, 
          setIsSearchLoading,
          setIsDetailsView
         } = useContext(GlobalContext);

      const searchSubmitHandler = () => {
        if (search.trim() === '') {
          toast.info("Veuillez mentionner votre recherche");
        } else if (search.trim().length > 100) {
          toast.warning("La recherche ne peut pas dépasser 100 caractères");
        } else {
          submit({ expression: search }, { method: 'get', action: 'search/find/' });
          setSearch('');
          setIsSearchLoading(true);
          setIsDetailsView(false);
        }
        
      };
      
      const searchHandleChange = (event) => {
        setSearch(event.target.value);
      };
    
      const toggleMobileSidebar = () => {
        setIsMobileSidebarOpen((prevState) => !prevState);
      };

      useEffect(() => {
        initFlowbite();

            const handleResize = () => {
              setIsMobile(window.innerWidth <= 767);
            };

            window.addEventListener('resize', handleResize);
            handleResize();

            return () => {
              window.removeEventListener('resize', handleResize);
            };

      }, []);
    return (
            <>
            <nav className="fixed z-50 top-0 left-0 w-screen font-custom bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
              <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
              {location.pathname && location.pathname.indexOf("publications") !== -1 &&
              <button type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 
              focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              onClick={toggleMobileSidebar}>
                  <span className="sr-only">Open sidebar</span>
                  <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                  </svg>
              </button>
              }
              <Link to="/" className="flex items-center ml-0">
                  <img src="/images/marndr-logo.jpg" className="h-12" alt="Sist Logo" />
              </Link>
              {/* <DropdownLang /> */}
              {/* <DarkModeToggle />  */}
              <div className="flex md:order-2">
                <button type="button" data-collapse-toggle="navbar-search" aria-controls="navbar-search" aria-expanded="false" className="md:hidden text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5 mr-1" >
                  <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                  <span className="sr-only">Search</span>
                </button>
                <div className="relative hidden md:block w-[32rem]">
                  <div className={`absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none ${isSearchLoading ? 'hidden' : ''}`}>
                    <svg className="w-5 h-5 text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                    <span className="sr-only">Search icon</span>
                  </div>

                  <input type="search" id="search-navbar" 
                    className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 
                    focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500
                    dark:focus:border-blue-500" 
                    placeholder="Recherche documents..."
                    name="expression"
                    value={search}
                    onChange={searchHandleChange}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        searchSubmitHandler();
                      }
                    }}
                  />
                  <div
                    id="search-spinner"
                    aria-hidden
                    hidden={!isSearchLoading}
                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${isSearchLoading ? '' : 'hidden'}`}
                  >
                    {/* <span className="text-gray-500 text-sm">Loading...</span> */}
                    <div role="status">
                        <svg aria-hidden="true" className="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                  </div>  
                </div>

                <button data-collapse-toggle="navbar-search" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-search" aria-expanded="false">
                  <span className="sr-only">Open menu</span>
                  <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                </button>
                <div className="flex items-center justify-between ml-5 md:ml-24">
                    <Link to={!isMobile ? `${process.env.REACT_APP_BASE_LD_BASE_PATH}/login.jsp` : `${process.env.REACT_APP_BASE_LD_BASE_PATH}/login-mobile.jsp?switch=true&tenant=default`}
                         target='_blank' className="pr-3 text-sm text-blue-600 dark:text-blue-500 hover:underline">Connexion</Link>
                    {/* <DocumentViewer /> */}
                </div>
                {/* <div className='ml-5'><DropdownLang /></div> */}
              </div>
                <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-search">
                <div className="relative mt-3 md:hidden">
                  <div className={`absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none ${isSearchLoading ? 'hidden' : ''}`}>
                    <svg className="w-5 h-5 text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
                    </svg>
                  </div>
                  <input
                    type="search"
                    id="search-navbar"
                    className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Recherche documents..."
                    name="expression"
                    value={search}
                    onChange={searchHandleChange}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        searchSubmitHandler();
                      }
                    }}
                  />
                  <div
                    id="search-spinner"
                    aria-hidden
                    hidden={!isSearchLoading}
                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${isSearchLoading ? '' : 'hidden'}`}
                  >
                    <div role="status">
                      <svg aria-hidden="true" className="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                  <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                  {location.pathname === "/" && (
                    <li>
                      <Scroll to="section-sist" smooth={true} duration={500} offset={-70}>
                        <span className='cursor-pointer block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent 
                      md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white
                       md:dark:hover:bg-transparent dark:border-gray-700'>
                          A propos
                        </span>
                      </Scroll>
                    </li>
                  )}
                    <li>
                      <Link to="/publications" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent
                       md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white
                        md:dark:hover:bg-transparent dark:border-gray-700">
                          Publications
                      </Link>
                    </li>
                    {location.pathname === "/" && (
                    <li>
                      <Scroll to="contact" smooth={true} duration={500} offset={-70} >
                        <span className="cursor-pointer block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent 
                        md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white
                        md:dark:hover:bg-transparent dark:border-gray-700">
                          Contact
                        </span>
                      </Scroll>
                    </li>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
        {isMobileSidebarOpen && (
          <div
            className="lg:hidden fixed inset-0 bg-black bg-opacity-30 z-10"
            onClick={() => setIsMobileSidebarOpen(false)}
          ></div>
        )}
        </>
    );
  }

  export default Header;

  // export async function action({ params, request }) {
  //   const data = await request.formData();
  //   const expression = data.get('expression');
  //   alert("In Action : " +expression);
  //   console.log( "In action" );
  //   const response = await Queries.documentsSearch( expression, process.env.REACT_APP_FOLDER_ID );
  //   if(!response){
  //     throw new Response( JSON.stringify( {message: "error.response.data.message" } ), {status: "error.response.data.status" })
  //   }
  //   return redirect('/search/find');
  //   try {
  //     const response = await Queries.documentsSearch( expression, process.env.REACT_APP_FOLDER_ID );
  //     console.log(response);
  //     // const documents = mapping.document(response.data);
  //     //redirect to the listing documents if there are documents on the response
  //     if( response.data.length > 0){
  //       alert("In Action data length : " +expression);
  //       console.log(response.data)
  //       return redirect('/search/find');
  //     }else{
  //       alert("Pas de resultat pour cette recherche");
  //       window.location.reload();
  //     }
        
  //   } catch (error) {
  //     throw new Response( JSON.stringify( {message: error.response.data.message } ), {status: error.response.data.status })
  //   }
  // }