import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAudio, faFileVideo, faFilePdf, faFileWord, faFileAlt, faFileExcel, faFileArchive, faImage } from '@fortawesome/free-solid-svg-icons';

const thumbnail = (fileName) => { 
    const commonStyle = {
        width: '400px',
        height: '200px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };

    const audio = ["mp3", "ogg", "mpeg", "wav", "wma"];
    const video = ["mp4", "avi", "mkv", "flv", "web", "wmv", "mpeg"];
    const fileWord = ["doc", "docx"];
    const filePdf = ["pdf"];
    const fileTxt = ["txt"];
    const fileExcel = ["xls", "xlsx"];
    const filePowerPrint = ["ppt", "pptx"];
    const compressed= ["zip"];
    const image = ['png', 'jpg', 'jpeg', 'gif', 'ico', 'svg'];

    const extension = fileName.split('.').pop().toLowerCase();

    if (audio.includes(extension)) {
        return {composant: <div style={commonStyle}><FontAwesomeIcon icon={faFileAudio} size='5x' style={{ color: ' #FFA500' }}/></div>, type: "audio"};
    } else if (video.includes(extension)) {
        return {composant: <div style={commonStyle}><FontAwesomeIcon icon={faFileVideo} size='5x' style={{ color: '#343a40' }}/></div>, type: "video"}
    } else if (fileWord.includes(extension)) {
        return {composant: <div style={commonStyle}><FontAwesomeIcon icon={faFileWord} size='5x' style={{ color: '#007BFF' }}/></div>, type: "doc"};
    } else if (filePdf.includes(extension)) {
        return {composant: <div style={commonStyle}><FontAwesomeIcon icon={faFilePdf} size='5x' style={{ color: '#FF0000' }}/></div>, type: "pdf"};
    } else if (fileTxt.includes(extension)) {
        return {composant: <div style={commonStyle}><FontAwesomeIcon icon={faFileAlt} size='5x' style={{ color: '#6c757d' }}/></div>, type: "txt"};
    } else if (fileExcel.includes(extension)) {
        return {composant: <div style={commonStyle}><FontAwesomeIcon icon={faFileExcel} size='5x' style={{ color: '#007BFF' }}/></div>, type: "excel"};
    } else if(compressed.includes(extension)) {
        return {composant: <div style={commonStyle}><FontAwesomeIcon icon={faFileArchive} size='5x' style={{ color: '#28a745' }}/></div>, type: "compressed"};
    } else if(filePowerPrint.includes(extension)) {
        return {composant: <div style={commonStyle}><FontAwesomeIcon icon={faFileAlt} size='5x' style={{ color: '#28a745' }}/></div>, type: "ppt"};
    } else if(image.includes(extension)) {
        return {composant: <div style={commonStyle}><FontAwesomeIcon icon={faImage} size='5x' style={{ color: '#28a745' }}/></div>, type: "image"};
    } else {
        return {composant: <div style={commonStyle}><FontAwesomeIcon icon={faFileAlt} size='5x' style={{ color: '#343a40' }}/></div>, type: "unknown"};
    }
};

export default thumbnail;
