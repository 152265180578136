import React, { useEffect, useState } from "react";
import { Carousel } from "@material-tailwind/react";
import { getBinaryContent } from '../../../utils/document/GetBinaryContent';
import Queries from '../../../utils/queries';
import mapping from '../../../utils/mapping';
import { useTranslation } from "react-i18next";
import { objectURL, typeMIME } from '../../../utils/document/ObjectURL';
import { Spinner } from '@material-tailwind/react';

function Caroussel() {
  const [error, setError] = useState("");
  const [documents, setdocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [carouselItems, setCarouselItems] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const docs = async () => {
      try {
        const response = await Queries.documents(process.env.REACT_APP_FOLDER_ADMIN_ID);
        const documentsFetched = mapping.document(response.data);
        setdocuments(documentsFetched);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response) {
          console.log(`error.response`);
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          setError("Une erreur est survenue");
        } else {
          console.log("Error", error.message);
        }
      }
    };
    docs();
  }, []);

  const getUrl = async (document) => {
    const typemime = typeMIME(document);
    if (typemime.startsWith("image")) {
      const binaryContent = await getBinaryContent(document);
      let url = await objectURL(document, binaryContent);
      return url;
    }
  
    return null;
  };
  
  const renderCarouselItems = async () => {
    const items = [];
    
    for (let index = 0; index < documents.length; index++) {
        const document = documents[index];
        const url = await getUrl(document);
        if (url) {
          items.push(
            <div key={index} className="relative h-full w-full">
              <img src={url} alt="" className="h-full w-full object-cover" />
              <div className="absolute inset-0 rounded-xl grid h-full w-full place-items-center bg-black/10">
                <div className="w-3/4 text-center md:w-2/4 p-1">
                  {/* render for caroussel here */}
                </div>
              </div>
            </div>
          );
      
          setCarouselItems(items.slice());
      
          // waiting for get the image.
          await new Promise(resolve => {
            const img = new Image();
            img.onload = resolve;
            img.src = url;
          });
      }
    }
  };
  

  // const renderCarouselItems = async () => {
  //   const items = [];
  //   for (let index = 0; index < documents.length; index++) {
  //     const document = documents[index];
  //     const url = await getUrl(document);
  //     console.log("URL ", url);
  //     items.push(
  //       <div key={index} className="relative h-full w-full">
  //         <img src={url} alt="" className="h-full w-full object-cover" />
  //         <div className="absolute inset-0 rounded-xl grid h-full w-full place-items-center bg-black/10">
  //           <div className="w-3/4 text-center md:w-2/4 p-1">
  //             {/* Ajoutez ici le rendu que vous souhaitez pour chaque élément du carousel */}
  //           </div>
  //         </div>
  //       </div>
  //     );
  //     setCarouselItems(items.slice()); // Mettez à jour le state du carousel à chaque nouvelle image
  //     await new Promise(resolve => setTimeout(resolve, 500)); // Attendez un court délai avant de charger la prochaine image
  //   }
  // };

  useEffect(() => {
    const updateCarouselItems = async () => {
      await renderCarouselItems();
    };
    updateCarouselItems();
  }, [documents]);
  return (
    <section className="border-current h-[37rem] mt-16">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <Spinner className="h-8 w-8"/>
        </div>
      ) : (
       error ? (
        <Carousel>
          <div className="relative h-full w-full">
            <img
              src="/images/slide/1.jpg"
              alt=""
              className="h-full w-full object-cover"
            />
            {/* <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75"> */}
            <div className="absolute inset-0 rounded-xl grid h-full w-full place-items-center bg-black/10">
              <div className="w-3/4 text-center md:w-2/4 p-1">
                {/* <Typography
                  variant="h1"
                  color="white"
                  className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                >
                  Lorem ipsum
                </Typography>
                <Typography
                  variant="lead"
                  color="white"
                  className="mb-12 opacity-80"
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vehicula dolor ac odio consequat, in tincidunt neque ultrices.
                  Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                </Typography> */}
                {/* <div className="flex justify-center gap-2">
                  <Button size="lg" color="white">
                    Consulter
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="relative h-full w-full">
            <img
              src="/images/slide/2.jpg"
              alt=""
              className="h-full w-full object-cover"
            />
            <div className="absolute inset-0 grid h-full w-full items-center">
              <div className="w-3/4 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
                {/* <Typography
                  variant="h1"
                  color="white"
                  className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                >
                  Lorem ipsum
                </Typography>
                <Typography
                  variant="lead"
                  color="white"
                  className="mb-12 opacity-80"
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vehicula dolor ac odio consequat, in tincidunt neque ultrices.
                  Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                </Typography> */}
                {/* <div className="flex gap-2">
                  <Button size="lg" color="white">
                    Consulter
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="relative h-full w-full">
            <img
              src="/images/slide/3.jpg"
              alt=""
              className="h-full w-full object-cover"
            />
            <div className="absolute inset-0 grid h-full w-full items-end">
              <div className="w-3/4 pl-12 pb-12 md:w-2/4 md:pl-20 md:pb-20 lg:pl-32 lg:pb-32">
                {/* <Typography
                  variant="h1"
                  color="white"
                  className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                >
                  Lorem ipsum
                </Typography>
                <Typography
                  variant="lead"
                  color="white"
                  className="mb-12 opacity-80"
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vehicula dolor ac odio consequat, in tincidunt neque ultrices.
                  Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                </Typography> */}
                {/* <div className="flex gap-2">
                  <Button size="lg" color="white">
                    Consulter
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="relative h-full w-full">
            <img
              src="/images/slide/4.jpg"
              alt=""
              className="h-full w-full object-cover"
            />
            <div className="absolute inset-0 grid h-full w-full place-items-center">
              <div className="w-3/4 text-center md:w-2/4">
                {/* <Typography
                  variant="h1"
                  color="white"
                  className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                >
                  Lorem ipsum
                </Typography>
                <Typography
                  variant="lead"
                  color="white"
                  className="mb-12 opacity-80"
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vehicula dolor ac odio consequat, in tincidunt neque ultrices.
                  Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                </Typography> */}
                {/* <div className="flex justify-center gap-2">
                  <Button size="lg" color="white">
                    Consulter
                  </Button>
                </div> */}
              </div>
            </div>
          </div>

          <div className="relative h-full w-full">
            <img
              src="/images/slide/6.jpg"
              alt=""
              className="h-full w-full object-cover"
            />
            <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/10">
              <div className="w-3/4 text-center md:w-2/4">
                {/* <Typography
                  variant="h1"
                  color="white"
                  className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                >
                  Lorem ipsum
                </Typography>
                <Typography
                  variant="lead"
                  color="white"
                  className="mb-12 opacity-80"
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vehicula dolor ac odio consequat, in tincidunt neque ultrices.
                  Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                </Typography> */}
                {/* <div className="flex justify-center gap-2">
                  <Button size="lg" color="white">
                    Consulter
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
        </Carousel> ): (<Carousel>{carouselItems}</Carousel>
      ))}
    </section>
  );
}

export default Caroussel;
