import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Worker } from '@react-pdf-viewer/core';
import './index.css';
import App from './App';
import Spinner from './components/Spinner';
import { GlobalContextProvider } from './store/context';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <Suspense fallback={<Spinner />}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.8.162/build/pdf.worker.min.js">
          <App />
        </Worker>
        <ToastContainer />
      </Suspense>
    </GlobalContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
