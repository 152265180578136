import Queries from '../../queries';
export const getBinaryContent = async (document) => {
    try {
    const response = await Queries.getBinaryDocument(document.id);
    return response.data;
      
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if( error.response.status === 500 ){
        console.log( "Une erreur interne s'est produite lors du traitement de votre demande. Veuillez réessayer ultérieurement." );
      }
      console.log(error.response.data);
      console.log(error.response.status);
      // console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request);
      console.log("No response was received");
      
    } else {
      // Something happened in setting up the request that triggered an Error
      // console.log('Error', error.message);
      console.log('Something happened in setting up the request that triggered an Error');
    }
    // console.log(error.config);
    
  }
};