const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return `${text.substring(0, maxLength)}...`;
    }
    return text;
};

export const getFileNameWithoutExtension = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf(".");
    const nameWithoutExtension = fileName.substring(0, lastDotIndex);
    return truncateText(nameWithoutExtension, 20);
}