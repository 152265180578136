import React, { useState } from 'react';
import thumbnail from '../../utils/document/Thumbnail';
import { objectURL } from '../../utils/document/ObjectURL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEye, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { formatedAuthorsName, truncateText } from '../../utils/document/FormatedAuthorsName';
import { getFileNameWithoutExtension } from '../../utils/document/GetFileNameWithoutExtension';
import { Spinner } from '@material-tailwind/react';
import { getBinaryContent } from '../../utils/document/GetBinaryContent';
import HandlePreview from '../../utils/document/HandlePreview';
import ModalPreview from '../Modals';
import PDFModal from '../Modals/PDFModal';

const viewableFileTypeIcon = ["audio", "video", "file", "pdf", "txt", "image"];

function Document({ filteredDocuments, onIsDetailsView, onDetailsViewed }) {
  const [showDialog, setShowDialog] = useState(false);
  const [docPreview, setDocPreview] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [loadingPreviews, setLoadingPreviews] = useState({});
  const [getBinaryContentError, setGetBinaryContentError] = useState(null);

  const generateURL = async (document) => {
    try {
      setLoadingPreviews(prevState => ({ ...prevState, [document.id]: true }));
      const binaryContent = await getBinaryContent(document);
      const url = await objectURL(document, binaryContent);
      
      setPreviewURL(url);
      setDocPreview(document);
      setShowDialog(true);
      setLoadingPreviews(prevState => ({ ...prevState, [document.id]: false }));
    } catch (error) {
      setLoadingPreviews(prevState => ({ ...prevState, [document.id]: false }));
      // console.error("Une erreur s'est produite :", error);
    }
  };
  
  // const generateURL = async (document) => {
  //   try {
  //     setLoadingPreview(true); 
  //     const binaryContent = await getBinaryContent(document);
  //     const url = await objectURL(document, binaryContent);
      
  //     setPreviewURL(url);
  //     setDocPreview(document);
  //     setShowDialog(true);
  //     setLoadingPreview(false); 
  //   } catch (error) {
  //     setLoadingPreview(false); 
  //   }
  // };
  

  const handleCloseDialog = () => {
    setShowDialog(false);
    onDetailsViewed( null );
    //initialisation docPreview and preview url for avoid bugs
    URL.revokeObjectURL(previewURL);
    setPreviewURL(null);
    setDocPreview(null);
  };

  const handleDetailsView = ( document ) => {
    onIsDetailsView(true);
    onDetailsViewed( document );
  }

  const downloadBlob = (blobData, fileName) => {
    const blob = new Blob([blobData]);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const handleDownload = async (doc) => {
    try {
      const binaryContent = await getBinaryContent(doc);
      downloadBlob(binaryContent, doc.fileName);
    } catch (error) {
      console.error('Erreur lors du téléchargement du document :', error);
    }
  };

  return (
    <>
      {filteredDocuments.map((document) => (
        <div key={document.id} className="">
          <div className="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 drop-shadow-lg hover:drop-shadow-2xl">
            <div className="flex justify-center items-center h-28">
              {thumbnail(document.fileName).composant}
            </div>
            <div className="p-3">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white overflow-hidden flex justify-center items-center">
                <span className="text-sm text-center font-custom">
                  {getFileNameWithoutExtension(document.fileName)}
                </span>
              </h5>
              {document.attributes.length > 0 &&
                document.attributes.map((attribute, index) => {
                  if (attribute.name === "authors") {
                    return (
                      <p
                        key={index}
                        className="mb-3 font-normal text-gray-700 dark:text-gray-400"
                      >
                        <span className="text-sm font-custom">
                          Auteurs: {truncateText(formatedAuthorsName(attribute.value), 14)}
                        </span>
                        <br />
                      </p>
                    );
                  }
                  return null;
                })}
            </div>
            <div className="p-3 flex justify-around">
            {viewableFileTypeIcon.includes(thumbnail(document.fileName).type) && (
                <span className="mx-2 cursor-pointer">
                  {loadingPreviews[document.id] ? (
                    <Spinner className="h-4 w-4"/>
                  ) : (
                    <FontAwesomeIcon
                      icon={faEye}
                      size="lg"
                      style={{ color: '#007BFF' }}
                      onClick={() => generateURL(document)}
                    />
                  )}
                </span>
              )}
              <FontAwesomeIcon
                icon={faDownload}
                size="lg"
                className="mx-2 cursor-pointer"
                style={{ color: '#28a745' }}
                onClick={() => handleDownload(document)}
              />
              {document.attributes.length > 0 &&
                document.attributes.map((attribute, index) => {
                  if (attribute.name === "descriptionP") {
                    return (
                      <span key={index}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          size="lg"
                          className="mx-2 cursor-pointer"
                          style={{ color: '#17a2b8' }}
                          onClick={() => handleDetailsView(document)}
                        />
                      </span>
                    );
                  }
                  return null;
                })}
            </div>
          </div>
        </div>
      ))}
      {docPreview !== null && (
        docPreview.fileName.split('.').pop().toLowerCase() === 'pdf' ? (
          <PDFModal isVisible={showDialog} onClose={handleCloseDialog}>
            <HandlePreview previewURL={previewURL} docPreview={docPreview} />
          </PDFModal>
        ) : (
          <ModalPreview isVisible={showDialog} onClose={handleCloseDialog}>
            <HandlePreview previewURL={previewURL} docPreview={docPreview} />
          </ModalPreview>
        )
      )}
    </>
  );
}

export default Document;
