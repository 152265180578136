import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_LD_PATH_API,
  auth: {
    username: process.env.REACT_APP_USERNAME,
    password: process.env.REACT_APP_PASSWORD
  }
});

 const Queries = {
    async folders ( folderId ) {
      const response = await axiosInstance.get('/folder/folders', {
        params: {
          folderId: folderId
        }
      });
      return response;
    },

    async documentsSearch ( expression, folderId ) {
      const response = await axiosInstance.post( `/document/search/find`, {
        expression: expression,
        folderId: folderId,
      });

      return response;
    },

  async documents( folderId ) {
      const response = await axiosInstance.get( `/document/documents`, {
          params: {
            folderId: folderId
          }
        });
        return response;
  },

  async getBinaryDocument( docId ) {
      const response = await axiosInstance.get( `/document/getBinaryContent/${docId}`, {
        responseType: 'blob'
      });
    return response;
    },

  async getRecentDocuments() {
    const response = await axiosInstance.get( `/document/getRecentDocuments`, {
      params: {
        maxHits: process.env.REACT_APP_MAXHITS_LASTS_PUBLICATIONS
      }
    });
    return response;
    },

    // async getExtractedText() {
    //   const response = await axios.get( `${process.env.REACT_APP_BASE_LD_PATH_API}/document/getExtractedText`, {
    //     params: {
    //       docId: process.env.REACT_APP_HELPDOC_ID
    //     }
    //   });
    //   //const documents = mapping.document(response.data);
    //   return response;
    //   }
};

export default Queries;