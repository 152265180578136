import { NavLink } from "react-router-dom";
 
const currentYear = new Date().getFullYear();
 
export default function Footer() {
  return (
          <footer className="p-4 bg-gray-100 md:p-8 lg:p-10 dark:bg-gray-900" id="contact">
            <div className="mx-auto max-w-screen-xl text-center">
                <a href="#" className="flex justify-center items-center text-2xl font-semibold text-gray-900 dark:text-white">
                <img src="/images/marndr-logo.jpg" className="h-24 bg-gray-400" alt="Sist Logo" /> 
                </a>
                <p className="my-6 text-gray-500 dark:text-gray-400">
                  Ministère de l'Agriculture, des Ressources Naturelles et du Développement Rural.
                </p>
                <ul className="flex flex-wrap justify-center items-center mb-6 text-gray-900 dark:text-white">
                    <li>
                        <p className="mr-4 hover:underline md:mr-6 ">Boulevard Jean-Jacques Dessalines, Port-au-Prince</p>
                    </li>
                    <li>
                        <p className="mr-4 hover:underline md:mr-6 ">contact@marndr.com</p>
                    </li>
                    <li>
                        <p className="mr-4 hover:underline md:mr-6 ">+(509) 0000 0000</p>
                    </li>
                </ul>
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© {currentYear} <NavLink to="#" className="hover:underline">SIST - MARNDR</NavLink>. Tous Droits Réservés.</span>
            </div>
          </footer>
  );
}