export const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return `${text.substring(0, maxLength)}...`;
    }
    return text;
};

export const formatedAuthorsName = (names) => {
    const namesArray = names.split(';');
    if (namesArray.length > 0) {
        const formattedNames = namesArray.map((name) => name.trim());
        return formattedNames.join(', ');
    }
    return names;
};

