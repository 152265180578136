import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import RootLayout from './pages/Layouts/Root';
import Home from "./pages/Home";
import Publications, { loader as folderLoader } from "./pages/Publications";
import ErrorPage from "./pages/Errors";
import SearchResults, { loader as searchLoader } from "./pages/SearchResults";


const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage/>,
    children: [
      { 
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Home />,
            // loader: recentsDocsLoader,
          },
          {
            path: 'publications',
            element: <Publications />,
            loader: folderLoader,
          },
          {
            path: 'search/find/',
            element: <SearchResults />,
            loader: searchLoader,
            // action: searchAction,
          },
          // {
          //   path: 'preview/:docId',
          //   element: <DocumentViewer />,
          //   // loader: contentLoader,
          // },
        ]
      }
    ]
  },
  // {
  //   path: "/publications",
  //   element: <PublicationsLayout />,
  //   errorElement: <ErrorPage />,
  //   children: [
  //     {
  //       index: true,
  //       element: <Publications />,
  //       loader: folderLoader,
  //     },
  //     {
  //       index: ':docId',
  //       element: <DocumentDetails />,
  //       loader: documentDetailsLoader,
  //     },
  //   ],
  // },


]);

function App() {
  return <RouterProvider router={router} />;
  
}

export default App;
