import React from 'react';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function PDFViewer( {url} ) {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
  <div className="h-[700px]" >
          <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} />
  </div>
  )
}

export default PDFViewer;








// import React from 'react';
// import { Viewer } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// function PDFViewer({ url }) {
//     const toolbarPluginInstance = toolbarPlugin();
//     const { Toolbar } = toolbarPluginInstance;

//     return (
//         <div
//             className="rpv-core__viewer"
//             style={{
//                 border: '1px solid rgba(0, 0, 0, 0.3)',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 height: '90%',
//             }}
//         >
//             <div
//                 style={{
//                     alignItems: 'center',
//                     backgroundColor: '#eeeeee',
//                     borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
//                     display: 'flex',
//                     padding: '4px',
//                 }}
//             >
//                 <Toolbar>
//                     {props => {
//                         const {
//                             CurrentPageInput,
//                             Download,
//                             EnterFullScreen,
//                             GoToNextPage,
//                             GoToPreviousPage,
//                             NumberOfPages,
//                             Print,
//                             ShowSearchPopover,
//                             Zoom,
//                             ZoomIn,
//                             ZoomOut,
//                         } = props;

//                         return (
//                             <>
//                                 <div style={{ padding: '0px 2px' }}>
//                                     <ShowSearchPopover />
//                                 </div>
//                                 <div style={{ padding: '0px 2px' }}>
//                                     <ZoomOut />
//                                 </div>
//                                 <div style={{ padding: '0px 2px' }}>
//                                     <Zoom />
//                                 </div>
//                                 <div style={{ padding: '0px 2px' }}>
//                                     <ZoomIn />
//                                 </div>
//                                 <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
//                                     <GoToPreviousPage />
//                                 </div>
//                                 <div style={{ padding: '0px 2px', width: '4rem' }}>
//                                     <CurrentPageInput />
//                                 </div>
//                                 <div style={{ padding: '0px 2px' }}>
//                                     / <NumberOfPages />
//                                 </div>
//                                 <div style={{ padding: '0px 2px' }}>
//                                     <GoToNextPage />
//                                 </div>
//                                 <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
//                                     <EnterFullScreen />
//                                 </div>
//                                 <div style={{ padding: '0px 2px' }}>
//                                     <Download />
//                                 </div>
//                                 <div style={{ padding: '0px 2px' }}>
//                                     <Print />
//                                 </div>
//                             </>
//                         );
//                     }}
//                 </Toolbar>
//             </div>

//             <div
//                 style={{
//                     flex: 1,
//                     overflow: 'hidden',
//                 }}
//             >
//                 <Viewer fileUrl={url} plugins={[toolbarPluginInstance]} />
//             </div>
//         </div>
//     );
// }

// export default PDFViewer;

