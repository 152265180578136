import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEye, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import mapping from '../../../utils/mapping';
import Queries from '../../../utils/queries';
import {  toast } from 'react-toastify';
import { getBinaryContent } from '../../../utils/document/GetBinaryContent';


function Recent({documents}) {

  const getFileNameWithoutExtension = (fileName) => {
      const lastDotIndex = fileName.lastIndexOf(".");
      const nameWithoutExtension = fileName.substring(0, lastDotIndex);
      return nameWithoutExtension;
    };
    
  const downloadBlob = (blobData, fileName) => {
    const blob = new Blob([blobData]);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const handleDownload = async (doc) => {
    try {
      const binaryContent = await getBinaryContent(doc);
      downloadBlob(binaryContent, doc.fileName);
    } catch (error) {
      console.error('Erreur lors du téléchargement du document :', error);
    }
  };

return (
  <>
  <div className='mb-10'>
      <div className="w-full p-8 uppercase tracking-wide  text-sm md:text-md md:text-base lg:text-xl xl:text-2xl 2xl:text-3xl 
          text-center font-semibold antialiased font-custom">
              Dernières Publications
      </div>

          <div className='w-11/12 mx-auto'>
              <div className="grid lg:grid-cols-2 gap-4">
              {documents.length > 0 &&
              documents.map((document) => (
                  <div key={document.id}>
                      <div className="block max-w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800
                           dark:border-gray-700 dark:hover:bg-gray-700">
                              
                          <div className='float-right'>
                              <FontAwesomeIcon
                              icon={faDownload}
                              size="lg"
                              className="mx-2 cursor-pointer"
                              onClick={() => handleDownload(document)}
                              />
                          </div>
                          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{getFileNameWithoutExtension(document.fileName)}</h5>
                          {document.attributes.length > 0 &&
                            document.attributes.map((attribute) => {
                              if (attribute.name === "descriptionP") {
                                return (
                                  <p key={attribute.name}className="font-normal text-gray-700 dark:text-gray-400">
                                      {attribute.value}
                                  </p>
                                );
                              }
                              return null;
                          })}
                      </div>
                  </div>
               ))}
              </div>
          </div>

      <div className='w-48 mx-auto mt-6'>
          <Link to="/publications" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              Découvrir plus
              <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
          </Link>
      </div>
  </div>
  </>
);
}

export default Recent;


// function Recent({documents}) {

//     const getFileNameWithoutExtension = (fileName) => {
//         const lastDotIndex = fileName.lastIndexOf(".");
//         const nameWithoutExtension = fileName.substring(0, lastDotIndex);
//         return nameWithoutExtension;
//       }

//       const handleDownload = (docId) => {
//         window.location.href = `${process.env.REACT_APP_BASE_LD_PATH_API}/document/download/${docId}`;
//     };
//     const [documents, setDocuments] = useState([]);
//     useEffect(() => {
//         const fetchDocuments = async () => {
//             try{
//             const response = await Queries.getRecentDocuments();
//             const documents = mapping.document(response.data);
//             setDocuments(documents);
//             }catch(error){
//                 console.log("Recent Documents Error : " ,error);
//             }
//         };

//         fetchDocuments();
//     }, []);
//   return (
//     <>
//     <div className='mb-10'>
//         <div className="w-full p-8 uppercase tracking-wide  text-sm md:text-md md:text-base lg:text-xl xl:text-2xl 2xl:text-3xl 
//             text-center font-semibold antialiased font-custom">
//                 Dernières Publications
//         </div>

//             <div className='w-11/12 mx-auto'>
//                 <div className="grid lg:grid-cols-2 gap-4">
//                 {documents.length > 0 &&
//                 documents.map((document) => (
//                     <div key={document.id}>
//                         <div className="block max-w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800
//                              dark:border-gray-700 dark:hover:bg-gray-700">
                                
//                             <div className='float-right'>
//                                 <FontAwesomeIcon
//                                 icon={faDownload}
//                                 size="lg"
//                                 className="mx-2 cursor-pointer"
//                                 onClick={() => handleDownload(document.id)}
//                                 />
//                             </div>
//                             <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{getFileNameWithoutExtension(document.fileName)}</h5>
//                             {document.attributes.length > 0 &&
//                               document.attributes.map((attribute) => {
//                                 if (attribute.name === "descriptionP") {
//                                   return (
//                                     <p key={attribute.name}className="font-normal text-gray-700 dark:text-gray-400">
//                                         {attribute.value}
//                                     </p>
//                                   );
//                                 }
//                                 return null;
//                             })}
//                         </div>
//                     </div>
//                  ))}
//                 </div>
//             </div>

//         <div className='w-48 mx-auto mt-6'>
//             <Link to="/publications" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
//                 Découvrir plus
//                 <svg aria-hidden="true" className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
//             </Link>
//         </div>
//     </div>
//     </>
//   );
// }

// export default Recent;
