import React, { useEffect, useState } from 'react';


const getInitialTheme = () => {
    if (typeof window !== 'undefined' && window.localStorage) {
      const storedPrefs = window.localStorage.getItem('current-theme');
      if (typeof storedPrefs === 'string') {
        return storedPrefs;
      }
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        return 'dark';
      }
    } 
    return 'light';
  };

const GlobalContext = React.createContext({
    // theme: "light",
    isMobileSidebarOpen: false,
    isSearchLoading: false,
    isDetailsView: false,
});

export const GlobalContextProvider = (props) => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
    const [theme, setTheme] = useState(getInitialTheme);
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    
  const [isDetailsView, setIsDetailsView] = useState(false);

    const checkTheme = (existing) => {
        const root = window.document.documentElement;
        const isDark = existing === 'dark';
    
        root.classList.remove(isDark ? 'light' : 'dark');
        root.classList.add(existing);
    
        localStorage.setItem('current-theme', existing);
      };

    useEffect(() => {
        checkTheme(theme);
    }, [theme]);

    return (
      <GlobalContext.Provider value={{ 
        // theme: theme, 
        // setTheme: setTheme,
        isMobileSidebarOpen: isMobileSidebarOpen,
        setIsMobileSidebarOpen: setIsMobileSidebarOpen,
        isSearchLoading: isSearchLoading, 
        setIsSearchLoading : setIsSearchLoading,
        isDetailsView: isDetailsView, 
        setIsDetailsView : setIsDetailsView,
      }}>
        {props.children}
      </GlobalContext.Provider>
    );

}

export default GlobalContext;