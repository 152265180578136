import Caroussel  from '../../components/Home/Caroussel';
import Card from "../../components/Home/Card";
import Recent from "../../components/Home/Recent";
import mapping from '../../utils/mapping';
import Queries from '../../utils/queries';
import React, { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom';

const Home = () => {
    // const recentsDocs = useLoaderData();
    const [recentDocuments, setRecentDocuments] = useState([]);
    // console.log( "recentDocuments ", recentDocuments);

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const fetchDocuments = async () => {
            try{
            let response = await Queries.getRecentDocuments();
            const docs = mapping.document(response.data);
            setRecentDocuments(docs);
            setIsLoading(false);
            }catch(error){
                console.log("Recent Documents Error : " ,error);
            }
        };
        fetchDocuments();
    }, []);

    return (
        <>
            <Caroussel />
            <Card />
            {recentDocuments.length > 0 && <Recent documents={recentDocuments} /> } 
            {/* <Recent onRecentDocuments={onRecentDocumentsHandler} />  */}
        </>
    );
};

export default Home;
// export async function loader(){

//     try {
//         const response = await Queries.getRecentDocuments();
//         const documents = mapping.document(response.data);
//         return documents;
          
//       } catch (error) {
//         throw new Response( JSON.stringify( {message: error.response.data.message } ), {status: error.response.data.status });
//       }
// };

// export async function loader(){

//     try {
//         const response = await Queries.documents(process.env.REACT_APP_FOLDER_ID);
//         console.log( response );
//         const documents = mapping.document(response.data);
//         return documents;
          
//       } catch (error) {
//         throw new Response( JSON.stringify( {message: error.response.data.message } ), {status: error.response.data.status });
//       }
// };