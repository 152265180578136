export const mimeCategories = {
  audio: [
    { extension: "mp3", mime: "audio/mp3" },
    { extension: "mpeg", mime: "audio/mpeg" },
    { extension: "wav", mime: "audio/x-wav" },
    { extension: "wma", mime: "audio/x-ms-wma" },
  ],
  video: [
    { extension: "mpeg", mime: "video/mpeg" },
    { extension: "mp4", mime: "video/mp4" },
    { extension: "wmv", mime: "video/x-ms-wmv" },
    { extension: "avi", mime: "video/x-msvideo" },
    { extension: "flv", mime: "video/x-flv" },
    { extension: "web", mime: "video/web" },
    { extension: "ogg", mime: "application/ogg" },
  ],
  image: [
    { extension: "jpg", mime: "image/jpeg" },
    { extension: "png", mime: "image/png" },
    { extension: "jpeg", mime: "image/jpeg" },
    { extension: "gif", mime: "image/gif" },
    { extension: "ico", mime: "image/x-icon" },
    { extension: "svg", mime: "image/svg+xml" },
  ],
  document: [
    { extension: "pdf", mime: "application/pdf" },
    { extension: "xls", mime: "application/vnd.ms-excel" },
    { extension: "xlsx", mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    { extension: "ppt", mime: "application/vnd.ms-powerpoint" },
    { extension: "pptx", mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation" },
    { extension: "doc", mime: "application/msword" },
    { extension: "docx", mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
  ],
};

const extensionToMIME = {};

for (const category in mimeCategories) {
  mimeCategories[category].forEach(item => {
    extensionToMIME[item.extension] = item.mime;
  });
}

export const getTypeMIME = (extension) => {
  if (extension in extensionToMIME) {
    return extensionToMIME[extension];
  } else {
    return "application/octet-stream";
  }
};

export const typeMIME = ( document ) => {
    const extension = document.fileName.split('.').pop().toLowerCase();
    const typeMIME = getTypeMIME(extension);
    return typeMIME;
}

export const getExtensionFromTypeMIME = (typeMIME) => {
  for (const category in mimeCategories) {
    const categoryItems = mimeCategories[category];
    const foundItem = categoryItems.find(item => item.mime === typeMIME);
    if (foundItem) {
      return foundItem.extension;
    }
  }
  return null;
};

export const getExtensionFromDocument = (document) => {
  const extension = document.fileName.split('.').pop().toLowerCase();
  return extension;
};

export const objectURL = async (document, binaryContent) => {
  try {
    const MIME = typeMIME(document);
    let blob = new Blob([binaryContent], { type: MIME });

    const url = URL.createObjectURL(blob);
    return url;
  } catch (error) {
    throw error;
  }
};


// export const objectURL = async (document, binaryContent) => {
//   const MIME = typeMIME(document);
//   let blob = new Blob([binaryContent.data], { type: MIME });
//   console.log("blob : ", blob.type);

//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();

//     reader.addEventListener('load', function() {
//       // const url = reader.result;
//       const url = URL.createObjectURL(blob);
//       resolve(url);
//     });

//     reader.addEventListener('error', function(error) {
//       reject(error);
//     });

//     reader.readAsDataURL(blob);
//   });
// };
