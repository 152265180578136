import React, { useEffect } from 'react';
import { initFlowbite } from 'flowbite';
import {formatedAuthorsName} from '../../utils/document/FormatedAuthorsName';
const DocumentDetails = ( { docForDetails, children } ) => {

  useEffect(() => {
    initFlowbite();
  }, []);

  return (
    <div className=''>
      <div id="accordion-open" data-accordion="open">
        <h2 id="accordion-open-heading-1">
          <button type="button" className="flex items-center justify-between w-full p-4 font-medium text-left text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-800 bg-gradient-to-r from-green-100 via-orange-100 to-blue-50" data-accordion-target="#accordion-open-body-1" aria-expanded="true" aria-controls="accordion-open-body-1">
            <span className="flex items-center">
              <svg className="w-5 h-5 mr-2 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd">
                </path>
              </svg> Description
            </span>
            <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5"/>
            </svg>
          </button>
        </h2>
        <div id="accordion-open-body-1" className="hidden bg-white/90" aria-labelledby="accordion-open-heading-1">
          {docForDetails.attributes[0]?.stringValue !== undefined && (
              <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 text-black font-extrabold text-justify">
                {docForDetails.attributes[0].stringValue}
              </div>
            )}
        </div>
        <h2 id="accordion-open-heading-2">
          <button type="button" className="flex items-center justify-between w-full p-4 font-medium text-left text-gray-500 border
           border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 
           dark:text-gray-400 dark:hover:bg-gray-800 bg-gradient-to-r from-green-100 via-orange-100 to-blue-50" data-accordion-target="#accordion-open-body-2" aria-expanded="false" aria-controls="accordion-open-body-2">
            <span className="flex items-center"><svg className="w-5 h-5 mr-2 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>Auteurs</span>
            <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5"/>
            </svg>
          </button>
        </h2>
        <div id="accordion-open-body-2" className="hidden bg-white/90" aria-labelledby="accordion-open-heading-2">
          {docForDetails.attributes[1]?.stringValue !== undefined && (
            <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 text-black font-extrabold">
              {formatedAuthorsName(docForDetails.attributes[1].stringValue)}
            </div>
          )}
        </div>
        <h2 id="accordion-open-heading-3">
          <button type="button" className="flex items-center justify-between w-full p-4 font-medium text-left text-gray-500 border 
          border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 
          dark:text-gray-400 dark:hover:bg-gray-800 bg-gradient-to-r from-green-100 via-orange-100 to-blue-50" data-accordion-target="#accordion-open-body-3" aria-expanded="false" aria-controls="accordion-open-body-3">
            <span className="flex items-center"><svg className="w-5 h-5 mr-2 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path></svg>{docForDetails.fileName}</span>
            <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5 5 1 1 5"/>
            </svg>
          </button>
        </h2>
        <div id="accordion-open-body-3" className="hidden" aria-labelledby="accordion-open-heading-3">
          <div className="border border-t-0 border-gray-200 dark:border-gray-700">
            {children}
          </div>
        </div>
      </div>
    </div>
  );

};


export default DocumentDetails;