import React from 'react';
import { typeMIME, getExtensionFromDocument } from '../../document/ObjectURL';
import videojs from 'video.js';
import AudioPlayer from '../../../components/Preview/AudioPlayer';
import PDFViewer from '../../../components/Preview/PDFViewer';

const HandlePreview = ( {previewURL, docPreview} ) => {
    const playerRef = React.useRef(null);

    if (previewURL) {
      const mimeType = typeMIME(docPreview);
      const ext = getExtensionFromDocument(docPreview);
      
      if ( mimeType.startsWith('video/') ) {
        const videoJsOptions = {
          autoplay: true,
          controls: true,
          responsive: true,
          fluid: true,
          sources: [{
            src: previewURL,
            type: 'video/mp4'
          }]
        };
      
        const handlePlayerReady = (player) => {
          playerRef.current = player;
      
          // You can handle player events here, for example:
          player.on('waiting', () => {
            videojs.log('player is waiting');
          });
      
          player.on('dispose', () => {
            videojs.log('player will dispose');
          });
        };

        // return <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />;
        return <video className="w-full h-auto max-w-full border border-gray-200 rounded-lg dark:border-gray-700" controls>
            <source src={previewURL} type="video/mp4"/>
        </video>
      } else if ( mimeType.startsWith('audio/') ) {
        return  <AudioPlayer audioSource={previewURL} />;
      }else if (mimeType.startsWith('image/')) {
        return <div className="flex justify-center items-center">
        <img src={previewURL} className="h-[28rem] w-[48rem]" alt="Preview" />
      </div>
      
      } else if ((ext === 'doc') || (ext === 'docx')) {
        return <p className="w-full bg-white/90 p-4 md:p-8 text-black font-extrabold uppercase tracking-wide text-md md:text-md lg:text-xl xl:text-xl 2xl:text-xl text-center antialiased font-custom">
          Le Système d'Information Sientifique et Technique ne prend pas en charge la prévisualisation de ce type de document. 
          Veuillez le téléchargé.
        </p>
      } else if ((ext === 'xls') || (ext === 'xlsx')) {
        return <p className="w-full bg-white/90 p-4 md:p-8 text-black font-extrabold uppercase tracking-wide text-md md:text-md lg:text-xl xl:text-xl 2xl:text-xl text-center antialiased font-custom">
          Le Système d'Information Sientifique et Technique ne prend pas en charge la prévisualisation de ce type de document. 
          Veuillez le téléchargé.
        </p>;
      } else if ((ext === 'ppt') || (ext === 'pptx')) {
        return <p className="w-full bg-white/90 p-4 md:p-8 text-black font-extrabold uppercase tracking-wide text-md md:text-md lg:text-xl xl:text-xl 2xl:text-xl text-center antialiased font-custom">
          Le Système d'Information Sientifique et Technique ne prend pas en charge la prévisualisation de ce type de document.
           Veuillez le téléchargé.
        </p>;
      } else if ( (ext === 'pdf') ) {
        return <PDFViewer url={previewURL} />
      }else {
        return <p className="w-full bg-white/90 p-4 md:p-8 text-black font-extrabold uppercase tracking-wide text-md md:text-md lg:text-xl xl:text-xl 2xl:text-xl text-center antialiased font-custom">
          Le Système d'Information Sientifique et Technique ne prend pas en charge la prévisualisation de ce type de document. 
          Veuillez le téléchargé.
        </p>;
      }
    }
  };

  export default HandlePreview;