import React, { useState, useEffect, useContext } from 'react';
import Document from '../Document';
import {formatedAuthorsName} from '../../utils/document/FormatedAuthorsName';
import { getBinaryContent } from '../../utils/document/GetBinaryContent';
import { objectURL } from '../../utils/document/ObjectURL';
import GlobalContext from '../../store/context';

import HandlePreview from '../../utils/document/HandlePreview';
import DocumentDetails from '../DocumentDetails';

export function SearchResults({ documents }) {
  const [detailsViewed, setDetailsViewed] = useState(null);
  const [urlDetails, setUrlDetails] = useState(null);
  const { isDetailsView, setIsDetailsView } = useContext(GlobalContext);

  const onIsDetailsViewHandler = (isDetailsView) => {
    setIsDetailsView(isDetailsView);
  };

  const onDetailsViewedHandler = (detailsViewed) => { 
    setDetailsViewed(detailsViewed);
  };

  const handleReturn = () => {
    setIsDetailsView(false);
    setDetailsViewed(null);
    URL.revokeObjectURL(urlDetails);
    setUrlDetails(null);
  };

  useEffect( () => {
    const getUrl = async () => {
      try {
        let binaryContent = await getBinaryContent(detailsViewed);
        let url = await objectURL(detailsViewed, binaryContent);
        return url;
      } catch (error) {
        // Gérer l'erreur ici si nécessaire
      }
    };
  
    const fetchUrl = async () => {
      let url = await getUrl();
      setUrlDetails(url);
    };
  
    if (detailsViewed) {
      fetchUrl();
    }
  }, [detailsViewed]); 

  return (
    <div className=''>
      {isDetailsView ? (
        <div className="w-full">
            <div className='w-full flex justify-between items-center pr-2'>
              <div></div>
              <div className='bg-gradient-to-r from-green-50 to-blue-50 p-1 rounded-lg'>
                <span onClick={handleReturn} 
                  className="text-md text-orange dark:text-blue-500 hover:underline cursor-pointer sm:text-xl md:text-2xl font-bold font-custom">
                  Retour à la liste
                </span>
              </div>
            </div>
          {detailsViewed &&
          <DocumentDetails docForDetails={detailsViewed}>
            <HandlePreview previewURL={urlDetails} docPreview={detailsViewed} />
          </DocumentDetails>
          }
      </div>
      ) : (
        <div className="w-11/12 mx-auto">
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-10'>
            {documents.length !== 0 &&
              <Document
                filteredDocuments={documents}
                onIsDetailsView={onIsDetailsViewHandler} 
                onDetailsViewed={onDetailsViewedHandler}
              />
            }
          </div>
        </div>
      )}
    </div>
  );
}
