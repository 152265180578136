import { Link, useRouteError, useLocation } from "react-router-dom";
import React,  { useEffect, useContext } from 'react';
import GlobalContext from '../../store/context';
import PageContent from '../../components/Error/PageContent';

const ErrorPage = () => {
    const error = useRouteError();
    const { setIsSearchLoading } = useContext(GlobalContext);
    const location = useLocation();


    let title = "Une erreur est survenue";
    let message = "Nos équipes travaillent activement pour résoudre ce problème dans les plus brefs délais.";
    if(error.status === 500 ){
        message = JSON.parse(error.data).message; 
    //   message = error.data.message;
    }
  
    if(error.status === 404 ){
      title = "Resource non trouvé!"
    //   message = JSON.parse(error.data).message;
      message = 'Impossible de trouvé la resource indiqué.'
    }

    useEffect(() => {
        if( error ){
            setIsSearchLoading(false);
        }
    }, [error]);
    
    return (
        <>
            <PageContent title={title}>
                <p>{message}</p>
                {location.pathname !== "/" && (
                    <Link 
                        to="/" 
                        className="pr-3 hover:underline uppercase tracking-wide text-sm text-indigo-500 font-semibold">
                        Retour à la page d'accueil
                    </Link>
                )}
            </PageContent>
        </>
    );
};

export default ErrorPage;