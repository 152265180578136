import React from 'react'

function ModalPreview( { isVisible, onClose, children } ) {

  if( !isVisible ) return null;

  const handleClose = (event) => {
    if( event.target.id === 'modalPreviewWrapper' ) onClose();
  };

  return (
    <div className='fixed inset-0 z-50 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center' 
    id='modalPreviewWrapper' onClick={handleClose}>
      <div className="md:w-[600px] w-[90%] mx-auto flex flex-col">
        <button className='text-white text-xl place-self-end' onClick={() => onClose()}>X</button>
        <div className='bg-white p-2 round '>{children}</div>
      </div>
    </div>
  );
}

export default ModalPreview;