import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Tooltip,
} from "@material-tailwind/react";
import { FolderIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

import { useContext } from 'react';
import GlobalContext from '../../store/context';

import Queries from '../../utils/queries';
import mapping from '../../utils/mapping';
 
function Sidebar(props) {

  const [open, setOpen] = useState(0);
  const [subfolders, setSubfolders] = useState([]);
  const { isMobileSidebarOpen } = useContext(GlobalContext);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const handleSubfolderClick = (subfolderId) => { 
    props.onSelectedSubfolderId(subfolderId);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      const results = await Promise.all(
        props.folders.map(async (folder) => {
          let subfolderData;
          try{
          const subfolderResponse = await Queries.folders(folder.id);
          subfolderData = mapping.folder(subfolderResponse.data);
          }catch( error ){
            throw new Response( JSON.stringify( {message: error.response.data.message } ), {status: error.response.data.status })
          }
          return {
            folder,
            subfolders: subfolderData,
          };
        })
      );
      setSubfolders(results);
    };
    if( props.folders !== null ){
      fetchData();
    }
  }, [props.folders]);
 
  return (
    <>
    <div className={`${
          isMobileSidebarOpen
            ? "lg:hidden fixed z-50 top-16 left-0 w-64 h-screen overflow-y-auto p-4 transition-transform translate-x-0 bg-gradient-to-b from-white via-gray-50 to-gray-100 dark:bg-gray-700 border-r border-gray-50 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
            : "lg:block fixed z-40 top-16 left-0 w-72 h-screen overflow-y-auto p-4 transition-transform -translate-x-full bg-gradient-to-b from-white via-gray-50 to-gray-100 dark:bg-gray-700 border-r border-gray-50 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
        }`}>
      <div className="mb-2 md:mt-5 lg:mt-0 p-4">
          <Typography variant="h5" color="green" className="sm:text-xl md:text-3xl font-extrabold font-custom">
            Catégories
          </Typography>
      </div>
      <List className="">
        {subfolders.length > 0 &&  
          subfolders.map(({ folder, subfolders }) => (
            <Accordion
              open={open === folder.id}
              key={folder.id}
              icon={
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`mx-auto h-4 w-4 transition-transform ${open === folder.id ? "rotate-180" : ""}`}
                />
              }
            >
              <ListItem className="p-0" selected={open === folder.id} key={folder.id}>
                <AccordionHeader onClick={() => handleOpen(folder.id)} className="border-b-0 p-3">
                  <ListItemPrefix>
                    <FolderIcon className="h-5 w-5" />
                  </ListItemPrefix>
                  <Tooltip content={folder.name.replace(/\d+/g, '').trim()}>
                  <Typography color="blue-gray" className="mr-auto font-bold truncate">
                    {folder.name.replace(/\d+/g, '').trim()}
                  </Typography>
                  </Tooltip>
                </AccordionHeader>
              </ListItem>
              {subfolders && (
                <AccordionBody className="py-1">
                  <List className="p-0 ml-5">
                    {subfolders.map((subfolder) => (
                      <ListItem key={subfolder.id} onClick={() => handleSubfolderClick(subfolder.id)}>
                        <ListItemPrefix>
                          <FolderIcon className="h-5 w-5" />
                        </ListItemPrefix>
                        <Typography className="font-custom">
                          {subfolder.name.replace(/\d+/g, '').trim()}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </AccordionBody>
              )}
            </Accordion>
          ))
        }
        {subfolders.length === 0 && (
          <p>Chargement en cours...</p>
        )}
      </List>
    </div>
    </>
  );
}

export default Sidebar;