import { useState, useEffect } from 'react';
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

export default function DropdownFilter({ options, onChange }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const isMenuOpenHandler = () => {
    setIsMenuOpen((cur) => !cur);
    setIsMobileMenuOpen(false);
  };

  const handleOptionClick = (format) => {
    onChange(format);
    setIsMenuOpen(false);
    setIsMobileMenuOpen(false);
  };

  return (
    <div className="relative">
      <button
        className="flex items-center gap-2 py-2 pr-3"
        selected={isMenuOpen || isMobileMenuOpen}
        onClick={isMenuOpenHandler}
      >
        <FontAwesomeIcon icon={faFilter} className='' />
        <ChevronDownIcon
          strokeWidth={1.5}
          className={`hidden h-3 w-3 transition-transform lg:block ${isMenuOpen ? 'rotate-180' : ''}`}
        />
        <ChevronDownIcon
          strokeWidth={1.5}
          className={`block h-3 w-3 transition-transform lg:hidden ${isMobileMenuOpen ? 'rotate-180' : ''}`}
        />
      </button>
      {isMenuOpen && (
        <div className="absolute top-full left-0 z-10 mt-2 py-2 w-48 bg-white rounded-lg shadow-xl">
          {options.map((option) => (
            <div
              key={option}
              className="block px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}







// import { useState } from 'react';
// import { ChevronDownIcon } from "@heroicons/react/24/outline";
// import { NavLink } from "react-router-dom";
   
//   export default function DropdownFilter() {
//     const [isMenuOpen, setIsMenuOpen] = useState(false);
//     const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

//     const isMenObileOpenHandler = () => {
//       setIsMobileMenuOpen((cur) => !cur);
//       setIsMenuOpen(() => (!isMenuOpen));
//     };

//       return (
//         <div className="relative">
//             <button 
//               className="flex items-center gap-2 py-2 pr-3"
//               selected={isMenuOpen || isMobileMenuOpen}
//               onClick={isMenObileOpenHandler}
//             >
//                 Trier par
//                 <ChevronDownIcon
//                   strokeWidth={1.5}
//                   className={`hidden h-3 w-3 transition-transform lg:block ${
//                     isMenuOpen ? "rotate-180" : ""
//                   }`}
//                 />
//                 <ChevronDownIcon
//                   strokeWidth={1.5}
//                   className={`block h-3 w-3 transition-transform lg:hidden ${
//                     isMobileMenuOpen ? "rotate-180" : ""
//                   }`}
//                 />
//             </button>
//             {isMenuOpen && (
//               <div className="absolute top-full left-0 z-10 mt-2 py-2 w-48 bg-white rounded-lg shadow-xl">
//                 <NavLink to="#" className="block px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white">Videos</NavLink>
//                 <NavLink to="#" className="block px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white">Audios</NavLink>
//                 <NavLink to="#" className="block px-4 py-2 text-gray-800 hover:bg-indigo-500 hover:text-white">Images</NavLink>
//               </div>
//             )}
//         </div>
//       );
//   }