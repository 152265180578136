import React from 'react'

function PDFModal( { isVisible, onClose, children } ) {

  if( !isVisible ) return null;

  const handleClose = (event) => {
    if( event.target.id === 'modalPreviewWrapper' ) onClose();
  };

  return (
    <div className='fixed inset-0 z-50 bg-black bg-opacity-25 backdrop-blur-sm flex w-full min-h-screen border-2 border-gray-950' 
            id='modalPreviewWrapper' onClick={handleClose}>
      <div className="flex flex-col w-full">
        <div className='flex flex-col w-full bg-blue-0 pr-4 border-y'>
            <button className='text-white text-lg place-self-end' onClick={() => onClose()}>X</button>
            <div></div>
        </div>
        <div className='bg-white p-2 round '>{children}</div>
      </div>
    </div>
  );
}

export default PDFModal;