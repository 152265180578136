import React from 'react';

function AudioPlayer({ audioSource }) {
    return (
        <div>
            <audio controls>
                <source src={audioSource} type="audio/mpeg" />
                <source src={audioSource} type="audio/mp4" />
                <source src={audioSource} type="audio/wav" />
                Your browser does not support the audio element.
            </audio>
        </div>
    );
}

export default AudioPlayer;
